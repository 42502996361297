function SvgComponent(props) {
	return (
	  <svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 171.505 139.378"
		{...props}
	  >
		<path
		  d="M171.505 16.499a70.337 70.337 0 01-20.208 5.54c7.264-4.354 12.844-11.25 15.47-19.466a70.437 70.437 0 01-22.344 8.538A35.136 35.136 0 00118.74 0C99.308 0 83.553 15.754 83.553 35.185c0 2.758.311 5.444.912 8.019C55.22 41.737 29.295 27.728 11.94 6.44a35.019 35.019 0 00-4.764 17.69c0 12.207 6.211 22.977 15.653 29.286a35.047 35.047 0 01-15.937-4.4c-.004.146-.004.294-.004.442 0 17.048 12.129 31.268 28.225 34.503a35.224 35.224 0 01-15.89.602c4.478 13.979 17.472 24.152 32.87 24.435-12.042 9.438-27.214 15.063-43.7 15.063-2.84 0-5.64-.167-8.393-.492 15.572 9.984 34.067 15.81 53.938 15.81 64.72 0 100.113-53.616 100.113-100.114 0-1.526-.035-3.043-.102-4.553a71.483 71.483 0 0017.556-18.213z"
		  fill="#2aa9e0"
		/>
	  </svg>
	)
  }
  
  export default SvgComponent
  